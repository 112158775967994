import React, { useState, useContext, useEffect } from "react";
import { Layout, Spin, Alert } from "antd";
import { ProfileContext } from "../../providers/ProfileProvider";
import { StageContext } from "../../providers/CandidateStageProvider";
import AssessmentOptions from "./AssessmentOptions";
import { TestPassed } from "./TestPassed";
import { TestFailed } from "./TestFailed";
import { TestInReview } from "./TestInReview";
import { TestLinkNotAccessible } from "./TestLinkNotAccessible";
import { firestore } from "../../firebase";

const AlgorithmsTest = () => {
  const [isLoading, setLoading] = useState(false);
  const { profile, loading } = useContext(ProfileContext);
  const { stages, stagesLoading } = useContext(StageContext);
  const [attemptedAssessment, setAttemptedAssessment] = useState({});

  useEffect(() => {
    getCandidatesAssessments(profile);
  }, [profile]);

  const getCandidatesAssessments = async (record) => {
    try {
      const recordId = record.uid;

      const collectionRef = await firestore.collection("assessments");
      const docRef = await collectionRef.doc(recordId);
      const docSnapshot = await docRef.get();

      if (docSnapshot.exists) {
        const docData = {
          key: docSnapshot.id,
          ...docSnapshot.data(),
        };

        setAttemptedAssessment(docData);
      } else {
        console.log("Document does not exist");
      }
    } catch (e) {
      console.error("Error fetching document:", e);
    }
  };

  return (
    <Layout style={{ height: "100%", background: "white" }}>
      {isLoading ? (
        <Spin />
      ) : stages.screeningCallCompleted ? (
        stages.algoTestScheduled ? (
          stages.algoTestConducted ? (
            stages.algoTestCompleted ? (
              <TestPassed stageName={"Algorithm Test"} />
            ) : profile.rejectedOn ? (
              <TestFailed stageName={"Algorithm Test"} />
            ) : (
              <TestInReview />
            )
          ) : (
            <TestLinkNotAccessible assessmentDetails={attemptedAssessment} />
          )
        ) : (
          <AssessmentOptions />
        )
      ) : (
        <Alert
          message="Warning"
          description="You are not eligible for the Algorithm Test, please complete the previous
          stages first"
          type="warning"
          showIcon
        />
      )}
    </Layout>
  );
};

export default AlgorithmsTest;
