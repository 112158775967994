import React, { useState, useContext, useEffect } from "react";
import { Layout, Row, Col, Card, Divider } from "antd";
import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  Spin,
  notification,
} from "antd";
import axios from "axios";
import config from "../../firebaseConfig";
import { updateObjectWithId } from "../../utilities/db";
import ResumeUpload from "./ResumeUpload";
import * as _ from "lodash";
import { ProfileContext } from "../../providers/ProfileProvider";
import { UserContext } from "../../providers/UserProvider";
import { navigate } from "@reach/router";
import { stageMap } from "../../utilities/stageMap";
import { positionList, countryList } from "../../utilities/lists";
import { transitionEmail } from "../../utilities/emailMessages";

const Profile = () => {
  const { Title, Text } = Typography;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [resumeUrl, setResume] = useState("");
  const [update, doUpdate] = useState(0);
  const { profile, loading } = useContext(ProfileContext);
  const [isLoading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const [updateCompleted, setUpdateCompleted] = useState(false);

  useEffect(() => {
    // This useEffect will trigger when the update is completed
    if (updateCompleted && stageMap[profile.currentStage]) {
      navigate(`/dashboard/?tab=${stageMap[profile.currentStage].value}`);
    }
  }, [updateCompleted, profile.currentStage]);

  // To disable submit button at the beginning.
  useEffect(() => {
    form.setFieldsValue(profile);
    if (profile.resume) {
      setResume(profile.resume);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  function updateStatus(userId) {
    axios
      .post(`${config.apiUrl}/updateProfileStage`, {
        candidateId: userId,
      })
      .then((res) => {
        console.log(res, "check data");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function sendStageTransitionEmail(first_name, user_email) {
    axios
      .post(`${config.apiUrl}/sendTransitionEmailBySendGrid`, {
        from: "admin@teamo.io",
        email: user_email,
        first_name: first_name,
        subject: transitionEmail.profileToScreeningCall.emailSubject,
        template_body: transitionEmail.profileToScreeningCall.emailBody,
      })
      .then((res) => {
        notification.success({
          message: "Welcome to the Next Phase 🎉",
          duration: "4",
        });
      })
      .catch((error) => {
        console.log("Couldn't send email", error);
      });
  }

  const onFinish = async (values) => {
    doUpdate(1);
    if (!resumeUrl) {
      notification.warning({
        message: "Please upload resume/cv",
      });
      return;
    }
    setLoading(true);

    let uid = user.uid;
    values.linkedIn = values.linkedIn || "";
    values.github = values.github || "";
    values.stackoverflow = values.stackoverflow || "";
    values.resume = resumeUrl;

    if (profile.currentStage === "profile") {
      await updateStatus(uid);
      await sendStageTransitionEmail(values.firstName, user.email);
    }

    await updateObjectWithId("candidates", uid, values);
    setLoading(false);
    notification.success({
      message: "Profile Successfully Updated",
      duration: "2",
    });

    setUpdateCompleted(true);

    setTimeout(() => {
      setLoading(true);
    }, 5000);
    setLoading(false);
  };

  return (
    <Layout style={{ minHeight: "100%", background: "#fbfbfb" }}>
      <Spin spinning={isLoading} tip="Loading Profile..." size="large">
        <Row justify="center" style={{ textAlign: "left" }} gutter={16}>
          <Col span={16}>
            {profile.rejectedOn === "profile-stage" ? (
              <Row
                justify="center"
                style={{ textAlign: "left", marginTop: "120px" }}
                gutter={16}
              >
                <Title level={4} style={{ color: "#1e0f54" }}>
                  We apologize, but your application for this role has been
                  rejected.
                </Title>
              </Row>
            ) : (
              <Card style={{ margin: "1.25rem 0rem" }}>
                <Row justify="left" gutter={16}>
                  <Title
                    style={{ margin: "0rem 0.25rem 1.25rem  0.25rem " }}
                    level={4}
                  >
                    Developer Profile
                  </Title>
                </Row>
                <Row justify="left" gutter={16}>
                  <Text
                    strong
                    style={{ margin: "0rem 0.25rem 1.25rem  0.25rem " }}
                  >
                    Basic Information
                  </Text>
                </Row>
                <Form
                  form={form}
                  name=""
                  onFinish={onFinish}
                  initialValues={{
                    residence: "Pakistan",
                    citizenship: "Pakistan",
                  }}
                >
                  <Row justify="center" gutter={16}>
                    <Col span={12}>
                      <Text>First Name:</Text>
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            pattern: /^[A-Za-z]+(?:\s[A-Za-z]+)?$/,
                            message: "Please enter a valid first name",
                            max: 100,
                          },
                        ]}
                      >
                        <Input
                          name="firstName"
                          placeholder="First Name"
                          maxLength="50"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Text>Last Name:</Text>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            pattern: /^[A-Za-z]+(?:\s[A-Za-z]+)?$/,
                            message: "Please enter a valid first name",
                            max: 100,
                          },
                        ]}
                      >
                        <Input
                          name="lastname"
                          placeholder="Last Name"
                          maxLength="50"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify="center" gutter={16}>
                    <Col span={12}>
                      <Text>Country of Citizenship:</Text>
                      <Form.Item
                        name="citizenship"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input your country of citizenship!",
                          },
                        ]}
                      >
                        <Select
                          name="citizenship"
                          style={{ textAlign: "left" }}
                          showSearch
                          placeholder="Select Country of Citizenship"
                        >
                          {countryList.map((country, index) => {
                            return (
                              <Option key={index} value={country}>
                                {country}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Text>Country of Residence:</Text>
                      <Form.Item
                        name="residence"
                        rules={[
                          {
                            required: true,
                            message: "Please input your country of residence!",
                          },
                        ]}
                      >
                        <Select
                          name="residence"
                          style={{ textAlign: "left" }}
                          showSearch
                          placeholder="Select Country of Residence"
                        >
                          {countryList.map((country, index) => {
                            return (
                              <Option key={index} value={country}>
                                {country}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify="left" gutter={16}>
                    <Col span={12}>
                      <Text>Phone Number:</Text>
                      <Form.Item
                        name="Phone"
                        rules={[
                          {
                            type: "string",
                            required: true,
                            message: "Please input your phone number!",
                            // eslint-disable-next-line no-useless-escape
                            pattern: "^\\+?[0-9]*$",
                            max: 20,
                          },
                        ]}
                      >
                        <Input
                          type="tel"
                          placeholder="+92XXXXXXXXXX"
                          maxLength="20"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Text>
                        If offered a position, how quickly can you join?
                      </Text>
                      <Form.Item
                        name="notice"
                        rules={[
                          {
                            required: true,
                            message: "Please select one option",
                          },
                        ]}
                      >
                        <Select
                          name="notice"
                          style={{ textAlign: "left" }}
                          showSearch
                          placeholder=""
                          optionFilterProp="children"
                        >
                          <Option key={1} value={"Immediately (<1 week)"}>
                            {"Immediately (<1 week)"}
                          </Option>
                          <Option key={1} value={"2 weeks or less"}>
                            2 weeks or less
                          </Option>
                          <Option key={1} value={"A month or less"}>
                            A month or less
                          </Option>
                          <Option key={1} value={"Over a month"}>
                            Over a month
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="left" gutter={16}>
                    <Text
                      strong
                      style={{ margin: "0rem 0.25rem 1.25rem  0.25rem " }}
                    >
                      Preferred Position(s)
                    </Text>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="preferredPosition"
                        rules={[
                          {
                            required: true,
                            message: "Please input your preferred position!",
                          },
                        ]}
                      >
                        <Select
                          name="preferredPosition"
                          style={{ textAlign: "left" }}
                          showSearch
                          placeholder="Select Preferred Position(s)"
                          optionFilterProp="children"
                        >
                          {positionList.map((pos, index) => {
                            return (
                              <Option key={index} value={pos}>
                                {pos}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="left" gutter={16}>
                    <Text
                      strong
                      style={{ margin: "0rem 0.25rem 1.25rem  0.25rem " }}
                    >
                      Other Information (optional)
                    </Text>
                  </Row>
                  <Row justify="center" gutter={16}>
                    <Col span={8}>
                      <Text>LinkedIn:</Text>
                      <Form.Item
                        name="linkedIn"
                        rules={[
                          {
                            pattern:
                              /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/,
                            message: "Please enter a valid LinkedIn URL",
                            required: false,
                            max: 100,
                          },
                        ]}
                      >
                        <Input
                          value=""
                          name="linkedIn"
                          placeholder="https://www.linkedin.com/in/john-doe"
                          maxLength="100"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Text>Github:</Text>
                      <Form.Item
                        name="github"
                        rules={[
                          {
                            pattern:
                              /^(http(s)?:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9-]+(\/)?$/,
                            message:
                              "Please enter a valid GitHub profile link!",
                            required: false,
                            max: 100,
                          },
                        ]}
                      >
                        <Input
                          name="github"
                          value=""
                          placeholder="https://github.com/johndoe"
                          maxLength="100"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Text>Stack Overflow:</Text>
                      <Form.Item
                        name="stackoverflow"
                        rules={[
                          {
                            pattern:
                              /^(http(s)?:\/\/)?(www\.)?stackoverflow\.com\/users\/[0-9]+\/[a-zA-Z0-9-]+(\/)?$/,
                            message: "Please enter a valid profile link!",
                            required: false,
                            max: 100,
                          },
                        ]}
                      >
                        <Input
                          value=""
                          name="stackoverflow"
                          placeholder="https://stackoverflow.com/users/111/johndoe"
                          maxLength="100"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Text
                      strong
                      style={{
                        margin: "0rem 0.25rem 1.25rem  0.25rem ",
                      }}
                    >
                      Resume / CV
                    </Text>
                  </Row>
                  <ResumeUpload
                    resume={[resumeUrl, setResume]}
                    update={update}
                    form={form}
                  />
                  <Divider />
                  <Row justify="end" gutter={1}>
                    <Form.Item style={{ margin: "0px" }} shouldUpdate={true}>
                      {() => (
                        <>
                          {profile.currentStage === "profile" ? (
                            <Button
                              style={{ minWidth: "6rem" }}
                              type="primary"
                              htmlType="submit"
                            >
                              <strong id="submit-button">Submit</strong>
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{ minWidth: "6rem" }}
                            >
                              <div id="update-button">Update</div>
                            </Button>
                          )}
                        </>
                      )}
                    </Form.Item>
                  </Row>
                </Form>
              </Card>
            )}
          </Col>
        </Row>
      </Spin>
    </Layout>
  );
};

export default Profile;
