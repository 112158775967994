import React, { useEffect, useContext, useState, useRef } from "react";
import { Collapse } from "antd";
import DOMPurify from "dompurify";
import jobs from "./jobs.json";
import axios from "axios";
import config from "../firebaseConfig";
import { UserContext } from "../providers/UserProvider";
import {
  Layout,
  Row,
  Steps,
  Tag,
  Icon,
  Button,
  Typography,
  Tooltip,
  Divider,
  notification,
  Col,
  Avatar,
} from "antd";
import {
  ArrowRightOutlined,
  BorderOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { ProfileContext } from "../providers/ProfileProvider";
import { firestore } from "../firebase";
const { Panel } = Collapse;
const { Title, Text } = Typography;
const { Paragraph } = Typography;
const { Step } = Steps;

export default function Contracts() {
  const { user } = useContext(UserContext);
  const isInitialMount = useRef(true);
  const { profile, loading } = useContext(ProfileContext);
  const [request_id, setRequest_id] = useState([]);

  useEffect(() => {
    getRequestId();
  }, [profile]);

  let emailUid = {
    recipients: {
      Owner: "VHZygYRwL1ZDgS9MzjECerERX8l1",
      Developer: "VHZygYRwL1ZDgS9MzjECerERX8l1",
    },
  };
  function sendDocument(data) {
    if (user) {
      axios
        .post(`${config.apiUrl}/candidatesRequests-sendDocument/`, { data })
        .then((res) => {
          console.log(res, "check data");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  function downloadDocumentPdf(documentId, requestId) {
    if (user) {
      let data = {
        documentId,
        requestId,
      };
      axios({
        method: "post",
        url: `${config.apiUrl}/candidatesRequests-downloadDocumentPdf`,
        data: data,
      })
        .then((res) => {
          const file = new Blob([res.data], { type: "application/pdf" });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
          console.log(res.data, "check pdf");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const getRequestId = async () => {
    console.log("running");
    if (profile && profile.documents) {
      let documentsData = profile.documents;
      let reqid = Object.keys(documentsData);

      const id = reqid.map((data) => {
        let data2 = documentsData[data];
        console.log(data2, "check data");

        return { ...data2, id: data };
      });
      setRequest_id(id);
    }
  };
  function remindRecipient(requestId) {
    console.log(requestId, "test id");
    axios
      .post(`${config.apiUrl}/candidatesRequests-remindRecipient`, {
        requestId,
      })
      .then((res) => {
        console.log(res, "check data");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <Layout
      style={{
        overflowY: "hidden",
        backgroundColor: "white",
      }}
    >
      {request_id.map((data) => (
        <Panel
          style={{
            textAlign: "left",
            backgroundColor: "#eeeeee",
            marginTop: "0.4rem",
          }}
          header={
            <Row>
              <Layout
                style={{
                  backgroundColor: "#eeeeee",
                }}
              >
                <Title style={{ marginTop: "0.4rem" }} level={4}>
                  {" "}
                  {data.documentName}
                </Title>
              </Layout>
              {data.requestStatus == "inprogress" ? (
                <Button
                  onClick={() => {
                    // remindRecipient(data.id)
                    sendDocument(emailUid);
                  }}
                  style={{ width: "125px" }}
                  type="primary"
                >
                  Remind
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    downloadDocumentPdf(data.documentId, data.id);
                  }}
                  style={{ width: "125px" }}
                  type="primary"
                >
                  Download
                </Button>
              )}
            </Row>
          }
          key={data.uid}
        ></Panel>
      ))}
    </Layout>
  );
}
