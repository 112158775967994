import React from "react";
import Failed from "../../images/project-test.svg";
import { Row, Col, Space, Typography } from "antd";
const { Paragraph, Title } = Typography;

export const TestFailed = ({ stageName }) => {
  return (
    <Row style={{ margin: "auto 0" }} justify="center" align="middle">
      <Col span={24}>
        <Space direction="vertical">
          <img
            src={Failed}
            alt="algo-test-failed"
            style={{ margin: "1rem 0", height: "10rem" }}
          />
          <Title level={4} style={{ color: "#1e0f54" }}>
            We are sorry!
          </Title>
          <Paragraph>
            You could not pass our {stageName} stage. Better Luck next time!
          </Paragraph>
        </Space>
      </Col>
    </Row>
  );
};
