import React, { useState, useContext, useEffect } from "react";
import { auth, provider } from "../firebase";
import {
  Form,
  Row,
  Col,
  notification,
  Button,
  Input,
  Card,
  Typography,
  Space,
  Layout,
  Steps,
  Grid,
} from "antd";
import { useNavigate } from "@reach/router";
// Context imports
import { UserContext } from "../providers/UserProvider";

// Asset imports
import logo from "../images/logo.png";
import footer1 from "../images/footer1.png";

const { Step } = Steps;
const { useBreakpoint } = Grid;
const { Text } = Typography;
const SignIn = () => {
  // Context params
  const { user, loading } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (user && !loading) {
      navigate("/dashboard");
    }
  }, [user, navigate, loading]);

  // State variables
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [loggingIn, setLoggingIn] = useState(false);
  let [googleLoggingIn, setGoogleLoggingIn] = useState(false);
  const screens = useBreakpoint();
  let [cascade, setCascade] = useState(0);
  // Variables
  const layout = {
    labelCol: {
      span: 0,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const tailLayout = {
    wrapperCol: {
      // offset: 8,
      span: 24,
    },
  };
  useEffect(() => {
    if (cascade === 4) {
      setTimeout(() => {
        setCascade(0);
      }, 2000);
    } else {
      let newCascade = cascade + 1;
      setTimeout(() => {
        setCascade(newCascade);
      }, 2000);
    }
  }, [cascade]);
  // Notifications
  const signInSuccess = () => {
    const key = `open${Date.now()}`;
    notification.success({
      message: "Logged in successfully!",
      description: `Welcome back ${user ? user.displayName : ""}`,
      key,
    });
    setTimeout(() => {
      navigate(`/dashboard`);
    }, 500);
  };

  const signInFailure = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="danger"
        size="small"
        onClick={() => notification.close(key)}
      >
        Close
      </Button>
    );
    notification.error({
      message: "We could not sign you in",
      description: "Please check your email and password, or contact support.",
      btn,
      key,
    });
  };

  const googleSignInFailure = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="danger"
        size="small"
        onClick={() => notification.close(key)}
      >
        Close
      </Button>
    );
    notification.error({
      message: "We could not sign you in",
      description:
        "Something went wrong with your Google authentication. Please try again later. If the problem persists, contact support.",
      btn,
      key,
    });
  };

  // Form functions
  function signInWithEmailAndPassword(email, password) {
    setLoggingIn(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        signInSuccess();
      })
      .catch((err) => {
        console.log("err", err);
        // Push notification with error
        signInFailure();
      })
      .finally(() => {
        setLoggingIn(false);
      });
  }
  function signInWithGoogle() {
    setGoogleLoggingIn(true);
    auth
      .signInWithPopup(provider)
      .then(signInSuccess)
      .catch(googleSignInFailure)
      .finally(() => setGoogleLoggingIn(false));
  }

  // Sign In Page
  return (
    <Layout style={{ height: "100vh" }}>
      <Row justify="center" style={{ height: "100%" }} align="middle">
        <Col style={{ width: screens.md ? "50%" : "100%" }}>
          <Row justify="space-around" align="middle" gutter={[16, 16]}>
            <Col>
              <Card
                bordered={false}
                style={{ background: "#F0F2F5", width: "auto" }}
              >
                <Row justify="space-around" align="middle" gutter={[16, 16]}>
                  <Col>
                    <img className="logo" src={logo} alt="logo" />
                  </Col>
                </Row>
                <Form
                  {...layout}
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={() => {}}
                  onFinishFailed={() => {}}
                  style={{ paddingTop: "20px" }}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button
                      id="login-button"
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={loggingIn}
                      onClick={() => {
                        signInWithEmailAndPassword(email, password);
                      }}
                    >
                      Login
                    </Button>
                  </Form.Item>
                  {/* <Form.Item {...tailLayout}>
              <span>OR</span>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                type="ghost"
                style={{ width: "100%" }}
                loading={googleLoggingIn}
                onClick={() => {
                  signInWithGoogle();
                }}
              >
                <span>
                  Login With <GoogleOutlined />
                </span>
                <span>oogle</span>
              </Button>
            </Form.Item> */}
                </Form>
                <Button
                  type="link"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <Space>
                    <Text>Not registered?</Text>
                    <u>Sign up</u>
                  </Space>
                </Button>
                <Row>
                  {/* <img src={footer2} alt="" className="footer-image" /> */}
                  <img src={footer1} alt="" className="footer-image" />
                </Row>
              </Card>
            </Col>
            {/* <Button
          type="link"
          style={{ width: "100%" }}
          onClick={() => {
            navigate("/sign-up");
          }}
        >
          Sign up for an account
        </Button> */}
          </Row>
        </Col>
        {screens.md && (
          <Col
            style={{
              width: "50%",
              background: "white",
              height: "100%",
            }}
          >
            <Row justify="center" align="middle" style={{ height: "100%" }}>
              <Col>
                {/* <Row justify="space-around" align="middle" gutter={[16, 16]}>
                  <Col>
                    <img
                      style={{ height: "80px", maxWidth: "100%" }}
                      src={logo}
                      alt="logo"
                    />
                  </Col>
                </Row> */}
                <Row
                  style={{
                    fontSize: "2.5rem",
                    width: "450px",
                    color: "#1e0f54",
                    padding: "2rem 0",
                    maxWidth: "100%",
                  }}
                >
                  <span>
                    Become a{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bolder",
                      }}
                    >
                      remote developer
                    </span>{" "}
                    with Teamo
                  </span>
                </Row>
                {/* <Row>
                <h1 style={{ fontSize: "2rem" }}>Join Teamo</h1>
              </Row>
              <Row>
                <p>It's simple!</p>
              </Row> */}
                <Row justify="center" style={{ width: "100%" }}>
                  <Steps direction="vertical" current={cascade}>
                    <Step
                      title="Screening Call"
                      description="Jump on a quick call with us"
                    />
                    <Step
                      title="Algorithms Test"
                      description="Solve some computer science puzzles"
                    />
                    <Step
                      title="Technical Interview"
                      description="Technical evaluation with our engineers"
                    />
                    <Step
                      title="Peer Interview"
                      description="Fireside chat with our engineers"
                    />
                    <Step title="Onboarding" description="Welcome to Teamo!" />
                  </Steps>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Layout>
  );
};

export default SignIn;
