const firebaseConfig =
  process.env.REACT_APP_ENV === "production"
    ? {
        apiKey: "AIzaSyD7E48LO4BmtHmKW7Wwc59O5DLNGlrI5WE",
        authDomain: "teamoio.firebaseapp.com",
        databaseURL: "https://teamoio.firebaseio.com",
        projectId: "teamoio",
        storageBucket: "teamoio.appspot.com",
        messagingSenderId: "234507881897",
        appId: "1:234507881897:web:227d724a880d3c9406c8b8",
        measurementId: "G-BN51WB6S4Z",
        apiUrl: "https://us-central1-teamoio.cloudfunctions.net",
      }
    : {
        apiKey: "AIzaSyBlo5YPglUKFTkS4733RxfsSqRq1r5B-_c",
        authDomain: "teamo-staging.firebaseapp.com",
        databaseURL: "https://teamo-staging.firebaseio.com",
        projectId: "teamo-staging",
        storageBucket: "teamo-staging.appspot.com",
        messagingSenderId: "127163006316",
        appId: "1:127163006316:web:ba78c21699dd0b856d0749",
        measurementId: "G-VD7WCRDF88",
        apiUrl: "https://us-central1-teamo-staging.cloudfunctions.net",
      };
export default firebaseConfig;
