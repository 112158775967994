import React, { useState, useContext, useEffect } from "react";
import { Layout, Row, Spin, Button, Typography, Col, Alert, Space } from "antd";
import { ProfileContext } from "../providers/ProfileProvider";
import { StageContext } from "../providers/CandidateStageProvider";
import Finalization from "../images/finalization.svg";
import { CheckCircleTwoTone } from "@ant-design/icons";
import Winner from "../images/winner.svg";
import { firestore } from "../firebase";
import { UserContext } from "../providers/UserProvider";
import Calcom from "./Calcom";

const { Title, Paragraph } = Typography;
export default function ProfileFinalization() {
  const { profile, loading } = useContext(ProfileContext);
  const { stages, stagesLoading } = useContext(StageContext);
  const [isAllowed, setAllowed] = useState(true);
  const [scheduled, setScheduled] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [onboardingCallData, setOnboardingCallData] = useState(null);
  const { user } = useContext(UserContext);
  const [welcomeScreen, setWelcomeScreen] = useState(true);

  useEffect(() => {
    if (
      profile.currentStage === "profileFinalization" &&
      !stages.profileFinalizationCompleted
    ) {
      setAllowed(true);
    } else {
      console.log("loaded profile", false);
      setAllowed(false);
    }
  }, [profile, stages]);

  useEffect(() => {
    if (!user) {
      return;
    }

    try {
      const fetchData = firestore
        .collection("onboarding")
        .doc(user.uid)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setOnboardingCallData(snapshot.data());
          } else {
            console.log("No data");
          }
        });

      return () => {
        fetchData();
      };
    } catch (error) {
      console.error("Error querying database:", error);
    }
  }, [user, firestore]);

  useEffect(() => {
    if (
      profile?.currentStage === "profileFinalization" &&
      onboardingCallData?.status === "ACCEPTED"
    ) {
      setScheduled(true);
    } else if (
      !onboardingCallData?.createdAt ||
      onboardingCallData?.status === "CANCELLED"
    ) {
      setScheduled(false);
    } else {
      setScheduled(true);
    }
    window.dataLayer.push({
      event: "onboardingCallScheduled",
      scheduled: scheduled,
    });
  }, [onboardingCallData, profile, setScheduled, scheduled]);

  useEffect(() => {
    setLoading(loading || stagesLoading);
  }, [loading, stagesLoading]);

  const dateTimeString = onboardingCallData?.startTime;
  const dateTime = new Date(dateTimeString);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDateTime = dateTime.toLocaleString(undefined, options);

  return (
    <Layout style={{ height: "100%", background: "white" }}>
      {isLoading ? (
        <Row style={{ margin: "auto" }}>
          <Spin spinning={true} />
        </Row>
      ) : isAllowed ? (
        !scheduled ? (
          welcomeScreen ? (
            <Row style={{ margin: "auto 0" }} justify="center" align="middle">
              <Col>
                <Space direction="vertical">
                  <img
                    src={Winner}
                    alt="finalization"
                    style={{ margin: "1rem 0", height: "10rem" }}
                  />
                  <Title level={3} style={{ color: "#1e0f54" }}>
                    Welcome to Teamo Talent Network
                  </Title>
                  <Paragraph>
                    Welcome to our growing family of talented individuals!{" "}
                    <br /> We're excited to have you join our network and embark
                    on a journey of opportunities together.
                  </Paragraph>
                  <Button
                    type="primary"
                    onClick={() => {
                      setWelcomeScreen(false);
                    }}
                  >
                    Get Started
                  </Button>
                </Space>
              </Col>
            </Row>
          ) : (
            <Row style={{ margin: "auto 0" }} justify="center" align="middle">
              <Col>
                <Space direction="vertical">
                  <img
                    src={Finalization}
                    alt="finalization"
                    style={{ margin: "1rem 0", height: "10rem" }}
                  />
                  <Title level={4} style={{ color: "#1e0f54" }}>
                    Please pick a time slot for your onboarding call.
                  </Title>
                  <Paragraph>
                    Our hiring team will help you set up your Teamo profile so
                    you can start applying to your dream projects and jobs.
                  </Paragraph>
                  <Calcom
                    calcomLink="team/hiring-team/onboarding"
                    name={profile?.firstName + " " + profile?.lastName}
                    email={profile?.email}
                  />
                </Space>
              </Col>
            </Row>
          )
        ) : (
          <Row style={{ margin: "auto 0" }} justify="center" align="middle">
            {profile && scheduled && (
              <Col>
                <Row justify="center" align="middle">
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{
                      fontSize: "2rem",
                      margin: "1rem 0",
                    }}
                  />
                </Row>

                {onboardingCallData?.rescheduleUid &&
                onboardingCallData?.status === "ACCEPTED" ? (
                  <div>
                    <Title level={4} style={{ color: "#1e0f54" }}>
                      Your interview has been{" "}
                      <span style={{ color: "green" }}>re-scheduled!</span>
                    </Title>
                    <Paragraph>
                      Scheduled date and time:
                      <Title level={5} style={{ color: "#1e0f54" }}>
                        {formattedDateTime}
                      </Title>
                    </Paragraph>
                  </div>
                ) : (
                  <div>
                    <Title level={4} style={{ color: "#1e0f54" }}>
                      Your interview has been scheduled!
                    </Title>
                    <Paragraph>
                      Scheduled date and time:{" "}
                      <Title level={5} style={{ color: "#1e0f54" }}>
                        {formattedDateTime}{" "}
                      </Title>
                    </Paragraph>
                  </div>
                )}

                <Paragraph>
                  {profile.onboarding && profile.onboarding.startTime
                    ? `Your interview is scheduled for ${profile.onboarding.startTime}`
                    : ""}
                </Paragraph>
                {profile.onboarding && profile.onboarding.startTime && (
                  <Row justify="center" align="middle">
                    <Space direction="horizontal">
                      <Button
                        onClick={() => {
                          window.open(
                            profile &&
                              profile.onboarding &&
                              profile.onboarding.rescheduleLink,
                            "_blank"
                          );
                        }}
                        type="primary"
                      >
                        Reschedule
                      </Button>
                      <Button
                        onClick={() => {
                          window.open(
                            profile &&
                              profile.onboarding &&
                              profile.onboarding.cancelLink,
                            "_blank"
                          );
                        }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Row>
                )}
              </Col>
            )}
          </Row>
        )
      ) : stages.profileFinalizationCompleted ? (
        <Row style={{ margin: "auto 0" }} justify="center" align="middle">
          <Col>
            <Space direction="vertical">
              <img
                src={Winner}
                alt="winner"
                style={{ margin: "1rem 0", height: "10rem" }}
              />
              <Title level={4} style={{ color: "#1e0f54" }}>
                Congratulations!
              </Title>
              <Paragraph>
                <strong> That's it, you made it! 🚀 </strong>
                <br />
                Your profile is being built out by our team and we will be
                sharing a link to you once it is ready.
                <br />
                We appreciate your patience in this regard.
              </Paragraph>
            </Space>
          </Col>
        </Row>
      ) : (
        <Alert
          message="Warning"
          description="You are not allowed to start the onboarding"
          type="warning"
          showIcon
        />
      )}
    </Layout>
  );
}
