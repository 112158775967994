import React from "react";
import "./App.css";
import { Layout } from "antd";
import { Router } from "@reach/router";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import Jobs from "./components/Jobs";
import Home from "./components/Home";
import Efset from "./pages/Efset";
import UserProvider from "./providers/UserProvider";
import StageProvider from "./providers/CandidateStageProvider";
import ProfileProvider from "./providers/ProfileProvider";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <StageProvider>
          <ProfileProvider>
            <Layout style={{ minHeight: "100vh", overflow: "hidden" }}>
              <Router>
                <SignUp path="/" />
                <SignIn path="/signin" />
                <Dashboard path="dashboard">
                  <Home path="" />
                  <Jobs path="jobs" />
                </Dashboard>
              </Router>
            </Layout>
          </ProfileProvider>
        </StageProvider>
      </UserProvider>
    </div>
  );
}

export default App;
