export const stageMap = { 
  profile: {
    value: 2,
    name: "Profile",
    description: "Tell us a bit about yourself.",
  },
  screeningCall: {
    value: 3,
    name: "Screening Call",
    description: "Let's get to know each other over a short call.",
  },
  algoTest: {
    value: 4,
    name: "Algorithms Test",
    description: "A short test to evaluate your problem solving skills",
  },
  technicalInterview: {
    value: 5,
    name: "Technical Interview",
    description:
      "We will evaluate your skills and knowledge based on your preferred roles",
  },
  peerInterview: {
    value: 6,
    name: "Peer Interview",
    description:
      "We will evaluate your skills and knowledge based on your preferred roles",
  },
  profileFinalization: {
    value: 7,
    name: "Onboarding",
    description:
      "An onboarding session to help you get started on your journey with Teamo",
  },
  active: { value: 8, name: "Done", description: "" },
};
