import React, { useState, useContext, useEffect } from "react";
import { auth, provider } from "../firebase";
import axios from "axios";
import config from "../firebaseConfig";

// Antd imports
import {
  Form,
  Row,
  Col,
  notification,
  Button,
  Input,
  Card,
  Space,
  Typography,
  Layout,
  Carousel,
  Steps,
  Grid,
} from "antd";

import { GoogleOutlined } from "@ant-design/icons";

// Context imports
import { UserContext } from "../providers/UserProvider";
import { storeObjectWithId } from "../utilities/db";
// Asset imports
import logo from "../images/logo.png";
import footer1 from "../images/footer1.png";
import { useNavigate } from "@reach/router";
const { Step } = Steps;
const { useBreakpoint } = Grid;
const { Text } = Typography;
const SignUp = () => {
  // Context params
  const { user, loading } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (user && !loading) {
      navigate("/dashboard");
    }
  }, [user, navigate, loading]);

  // State variables
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [signingUp, setSigningUp] = useState(false);
  const screens = useBreakpoint();
  let [googleLoggingIn, setGoogleLoggingIn] = useState(false);
  let [cascade, setCascade] = useState(0);
  useEffect(() => {
    if (cascade === 4) {
      setTimeout(() => {
        setCascade(0);
      }, 2000);
    } else {
      let newCascade = cascade + 1;
      setTimeout(() => {
        setCascade(newCascade);
      }, 2000);
    }
  }, [cascade]);
  // Variables
  let errorMessage =
    "Please check your email and password, or contact support.";
  const layout = {
    labelCol: {
      span: 0,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const tailLayout = {
    wrapperCol: {
      // offset: 8,
      span: 24,
    },
  };

  // Notifications
  const signUpSuccess = async (candidate) => {
    // create candidate on firebase
    let id = candidate.user.uid;
    await storeObjectWithId("candidates", id, {
      email: email,
      currentStage: "profile",
    });
    const key = `open${Date.now()}`;
    notification.success({
      message: "Welcome to Teamo Developer Platform!",
      description: `Thank you for signing up`,
      key,
    });
    //re route to dashboard
    navigate(`/dashboard`);
  };

  const signUpFailure = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="danger"
        size="small"
        onClick={() => notification.close(key)}
      >
        Close
      </Button>
    );
    notification.error({
      message: "We could not sign you up",
      description: errorMessage,
      btn,
      key,
    });
  };

  function sendWelcomeEmailOnSignup(candidate) {
    axios
      .post(`${config.apiUrl}/sendWelcomeEmailBySendGrid`, {
        email: candidate.user.email,
        from: "admin@teamo.io",
      })
      .then((res) => {
        console.log("email sent successfully!");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const googleSignInFailure = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="danger"
        size="small"
        onClick={() => notification.close(key)}
      >
        Close
      </Button>
    );
    notification.error({
      message: "We could not sign you in",
      description:
        "Something went wrong with your Google authentication. Please try again later. If the problem persists, contact support.",
      btn,
      key,
    });
  };

  // Form functions
  async function signUp(email, password) {
    setSigningUp(true);
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        signUpSuccess(user);
        sendWelcomeEmailOnSignup(user);
      })
      .catch(async (error) => {
        // Push notification with error
        if (error.message !== undefined || error.message !== "") {
          errorMessage = error.message;
        }
        signUpFailure();
      })
      .finally(() => {
        setSigningUp(false);
      });
  }

  function signInWithGoogle() {
    setGoogleLoggingIn(true);
    auth
      .signInWithPopup(provider)
      .then((user) => {
        signUpSuccess(user);
      })
      .catch(googleSignInFailure)
      .finally(() => setGoogleLoggingIn(false));
  }
  // Sign In Page
  return (
    <Layout style={{ height: "100vh", background: "" }}>
      <Row justify="center" style={{ height: "100%" }} align="middle" wrap>
        <Col style={{ width: screens.md ? "50%" : "100%" }}>
          <Row justify="space-around" align="middle" gutter={[16, 16]}>
            <Col>
              <Card
                bordered={false}
                style={{ background: "#F0F2F5", width: "auto" }}
              >
                <Row justify="center" align="middle" gutter={[16, 16]}>
                  {/* {screens.md ? (
                    <h1 style={{ fontSize: "2rem", color: "#1e0f54" }}>
                      Register to begin
                    </h1>
                  ) : ( */}
                  <Col>
                    <img className="logo" src={logo} alt="logo" />
                  </Col>
                  {/* )} */}
                </Row>
                <Form
                  {...layout}
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={() => {}}
                  onFinishFailed={() => {}}
                  style={{ paddingTop: "20px" }}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button
                      id="signup-button"
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={signingUp}
                      onClick={() => {
                        signUp(email, password);
                      }}
                    >
                      Register
                    </Button>
                  </Form.Item>
                  {/* <Form.Item {...tailLayout}>
              <span>OR</span>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                type="ghost"
                style={{ width: "100%" }}
                loading={googleLoggingIn}
                onClick={() => {
                  signInWithGoogle();
                }}
              >
                <span>
                  Sign up with <GoogleOutlined />
                </span>
                <span>oogle</span>
              </Button>
            </Form.Item> */}
                </Form>
                <Button
                  type="link"
                  onClick={() => {
                    navigate("/signin");
                  }}
                >
                  <Space>
                    <Text>Already registered?</Text>
                    <u>Sign in</u>
                  </Space>
                </Button>
                <Row>
                  <img src={footer1} alt="" className="footer-image" />
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        {screens.md && (
          <Col
            style={{
              width: "50%",
              background: "white",
              height: "100%",
            }}
          >
            <Row justify="center" align="middle" style={{ height: "100%" }}>
              <Col>
                {/* <Row justify="space-around" align="middle" gutter={[16, 16]}>
                  <Col>
                    <img
                      style={{ height: "80px", maxWidth: "100%" }}
                      src={logo}
                      alt="logo"
                    />
                  </Col>
                </Row> */}
                <Row
                  style={{
                    fontSize: "2.5rem",
                    width: "450px",
                    color: "#1e0f54",
                    padding: "2rem 0",
                    maxWidth: "100%",
                  }}
                >
                  <span>
                    Become a{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bolder",
                      }}
                    >
                      remote developer
                    </span>{" "}
                    with Teamo
                  </span>
                </Row>
                {/* <Row>
                <h1 style={{ fontSize: "2rem" }}>Join Teamo</h1>
              </Row>
              <Row>
                <p>It's simple!</p>
              </Row> */}
                <Row justify="center" style={{ width: "100%" }}>
                  <Steps direction="vertical" current={cascade}>
                    <Step
                      title="Screening Call"
                      description="Jump on a quick call with us"
                    />
                    <Step
                      title="Algorithms Test"
                      description="Solve some computer science puzzles"
                    />
                    <Step
                      title="Technical Interview"
                      description="Technical evaluation with our engineers"
                    />
                    <Step
                      title="Peer Interview"
                      description="Fireside chat with our engineers"
                    />
                    <Step title="Onboarding" description="Welcome to Teamo!" />
                  </Steps>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Layout>
  );
};

export default SignUp;
