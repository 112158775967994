import { firestore } from "../firebase";
export const storeObject = async (collection, object) => {
  await firestore
    .collection(collection)
    .add(object)
    .catch(() => {
      return false;
    });
  return true;
};

export const storeObjectWithId = async (collection, doc, object) => {
  const obj = await firestore
    .collection(collection)
    .doc(doc)
    .set(object)
    .catch((e) => {
      console.error(e);
      return false;
    });
  console.log(obj);
  return true;
};

export const updateObjectWithId = async (collection, doc, object) => {
  const obj = await firestore
    .collection(collection)
    .doc(doc)
    .update(object)
    .catch((e) => {
      console.error(e);
      return false;
    });
  console.log(obj);
  return true;
};

export const retrieveObject = async (collection, document) => {
  const docRef = await firestore.collection(collection).doc(document);
  const data = await docRef
    .get()
    .then((doc) => {
      if (!doc.exists) return false;
      return doc.data();
    })
    .catch(() => {
      return false;
    });
  return data;
};

export const retrieveAllDocuments = async (collectionName) => {
  try {
    const collectionRef = await firestore.collection(collectionName);
    const snapshot = await collectionRef.get();
    const data = snapshot.docs.map((doc) => doc.data());
    return data;
  } catch (error) {
    console.log("Error fetching document data:", error);
    return false;
  }
};
