import React, { useState } from "react";
import axios from "axios";
import config from "../firebaseConfig";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
const { Title, Text } = Typography;
const { confirm } = Modal;
const ModalText = () => {
  return (
    <>
      <Text>
        Clicking "Start" will take you to the test link. Alternatively, you will
        also receieve the test link on your email address.
      </Text>
      <br></br>
      <br></br>
      <Text>
        Please make sure that you are ready and ensure that you are in a quiet,
        comfortable location before starting the assessment.
      </Text>
      <br></br>
      <br></br>
      <Text strong>
        Once you click "Start" you will not be able to access the assessment
        again.
      </Text>
    </>
  );
};

async function getTestInvite(user, assessmentUrl) {
  if (user) {
    return await axios
      .post(`${config.apiUrl}/inviteCandidateToAssessment`, {
        candidate: user.email,
        assessmentUrl: assessmentUrl,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        err = err.data;
        if (err) {
          showError(err.error || "Error!");
        }
      });
  }
}

function showError(error) {
  Modal.error({
    title: error,
    okButtonProps: {
      danger: true,
    },
  });
}

export async function codingAssessment(user, assessmentUrl) {
  try {
    confirm({
      title: (
        <>
          <Title level={4}>Start Assessment</Title>
        </>
      ),
      okText: "Start",
      cancelText: "Cancel",
      maskClosable: true,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: <ModalText />,
      async onOk() {
        const testInvite = await getTestInvite(user, assessmentUrl);
        window.open(testInvite.data.candidates[0].url);
      },
      async onCancel() {
        console.log("link not generated!");
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export default function CodeButton({
  user,
  assessmentUrl,
  assessmentId,
  hoveredCardId,
}) {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      style={{
        width: "70%",
        margin: "0 auto",
        border: "1px solid #1e0f54",
        background: hoveredCardId === assessmentId ? "#1e0f54" : "white",
        color: hoveredCardId === assessmentId ? "white" : "#1e0f54",
      }}
      loading={loading}
      onClick={() => {
        setLoading(true);
        codingAssessment(user, assessmentUrl).finally(() => {
          setLoading(false);
        });
      }}
    >
      Begin Assessment
    </Button>
  );
}
