import React, { createContext, Component } from "react";
import { auth } from "../firebase";
export const UserContext = createContext();

class UserProvider extends Component {
  state = {
    user: null,
    loading: true,
  };
  unsubscribeFromAuth = null;

  componentDidMount = async () => {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth && userAuth.uid) {
        this.setState({ user: userAuth, loading: false });
      } else {
        this.setState({ user: null, loading: false });
      }
    });
  };

  componentWillUnmount = () => {
    this.unsubscribeFromAuth();
  };

  render() {
    const { user, loading } = this.state;
    const { children } = this.props;

    return (
      <UserContext.Provider value={{ user, loading }}>
        {children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
