import React, { useEffect, useRef } from "react";

export default function Calcom({ calcomLink, name, email }) {
  const scriptRef = useRef(null);

  useEffect(() => {
    if (!scriptRef.current) {
      const script = document.createElement("script");
      script.innerHTML = `
      (function (C, A, L) {
        let p = function (a, ar) {
          a.q.push(ar);
        };
        let d = C.document;
        C.Cal =
          C.Cal ||
          function () {
            let cal = C.Cal;
            let ar = arguments;
            if (!cal.loaded) {
              cal.ns = {};
              cal.q = cal.q || [];
              d.head.appendChild(d.createElement("script")).src = A;
              cal.loaded = true;
            }
            if (ar[0] === L) {
              const api = function () {
                p(api, arguments);
              };
              const namespace = ar[1];
              api.q = api.q || [];
              typeof namespace === "string"
                ? (cal.ns[namespace] = api) && p(api, ar)
                : p(cal, ar);
              return;
            }
            p(cal, ar);
          };
      })(window, "https://cal.teamo.io/embed/embed.js", "init");
      Cal("init");
            `;

      document.body.appendChild(script);

      scriptRef.current = script;
    }
  }, []);

  return (
    <>
      <button
        data-cal-config={`{"email":"${email}", "name": "${name}"}`}
        data-cal-link={calcomLink}
        className="calcom-button"
      >
        Schedule
      </button>
    </>
  );
}
