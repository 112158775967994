import React from "react";
import Inspection from "../../images/inspection.svg";
import { Row, Col, Space, Typography } from "antd";
const { Paragraph, Title } = Typography;

export const TestInReview = () => {
  return (
    <Row style={{ margin: "auto 0" }} justify="center" align="middle">
      <Col>
        <Space direction="vertical">
          <img
            src={Inspection}
            alt="inspection"
            style={{ height: "10rem", margin: "1rem 0" }}
          />
          <Title level={4} style={{ color: "#1e0f54" }}>
            Algorithms Test Submitted
          </Title>
          <Paragraph>
            Our team is evaluating your score. This page will automatically
            update once your results are in.
          </Paragraph>
        </Space>
      </Col>
    </Row>
  );
};
