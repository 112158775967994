import React, { createContext, useContext, useState, useEffect } from "react";
import { firestore } from "../firebase";
import { UserContext } from "./UserProvider";

export const ProfileContext = createContext();
const ProfileProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  useEffect(() => {
    if (user) {
      setLoading(true);
      const unsubscribe = firestore
        .collection("candidates")
        .doc(user.uid)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            let data = snapshot.data();
            console.log("data", data);
            setLoading(false);
            setProfile({ ...data, uid: user.uid });
          }
        });
      return () => {
        unsubscribe();
      };
    }
  }, [user]);
  return (
    <ProfileContext.Provider
      value={{
        profile: profile,
        loading: loading,
        error: isError,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
