import React, { useState, useContext, useEffect } from "react";
import { Layout, Row, Spin, Button, Typography, Col, Alert, Space } from "antd";
import { ProfileContext } from "../providers/ProfileProvider";
import { StageContext } from "../providers/CandidateStageProvider";
import Interview from "../images/interview.svg";
import { CheckCircleTwoTone } from "@ant-design/icons";
import Winner from "../images/winner.svg";
import { firestore } from "../firebase";
import { UserContext } from "../providers/UserProvider";
import Calcom from "./Calcom";

const { Title, Paragraph } = Typography;
export default function PeerInterviewSchedule() {
  const { profile, loading } = useContext(ProfileContext);
  const { stages, stagesLoading } = useContext(StageContext);
  const [isAllowed, setAllowed] = useState(true);
  const [scheduled, setScheduled] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [peerCallData, setPeerCallData] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (
      profile.currentStage === "peerInterview" &&
      !stages.peerInterviewCompleted
    ) {
      setAllowed(true);
    } else {
      setAllowed(false);
    }
  }, [profile, stages]);

  useEffect(() => {
    if (!user) {
      return;
    }

    try {
      const fetchData = firestore
        .collection("peerInterviews")
        .doc(user.uid)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setPeerCallData(snapshot.data());
          } else {
            console.log("No data");
          }
        });

      return () => {
        fetchData();
      };
    } catch (error) {
      console.error("Error querying database:", error);
    }
  }, [user, firestore]);

  useEffect(() => {
    if (
      profile?.currentStage === "peerInterview" &&
      peerCallData?.status === "ACCEPTED"
    ) {
      setScheduled(true);
    } else if (
      !peerCallData?.createdAt ||
      peerCallData?.status === "CANCELLED"
    ) {
      setScheduled(false);
    } else {
      setScheduled(true);
    }
    window.dataLayer.push({
      event: "peerInterviewScheduled",
      scheduled: scheduled,
    });
  }, [peerCallData, profile, setScheduled, scheduled]);

  useEffect(() => {
    setLoading(stagesLoading || loading);
  }, [stagesLoading, loading]);

  //change the date time format
  const dateTimeString = peerCallData?.startTime;
  const dateTime = new Date(dateTimeString);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDateTime = dateTime.toLocaleString(undefined, options);

  const links = [
    "team/hiring-team/peer-interview-1",
    "team/hiring-team/peer-interview-2",
    "team/hiring-team/peer-interview-3",
  ];

  const randomIndex = Math.floor(Math.random() * links.length);
  const calcomLink = links[randomIndex];
  console.log("calcomLink", calcomLink);

  return (
    <Layout style={{ height: "100%", background: "white" }}>
      {isLoading ? (
        <Row style={{ margin: "auto" }}>
          <Spin spinning={true} />
        </Row>
      ) : isAllowed ? (
        profile?.rejectedOn === "peer-interview-stage" ? (
          <div>
            <Row justify="center" align="middle" style={{ margin: "10rem 0" }}>
              <Title level={4} style={{ color: "#1e0f54" }}>
                We apologize, but your application for this role has been
                rejected.
              </Title>
            </Row>
          </div>
        ) : !scheduled ? (
          <Row style={{ margin: "auto 0" }} justify="center" align="middle">
            <Col>
              <Space direction="vertical">
                <img
                  src={Interview}
                  alt="interview"
                  style={{ margin: "1rem 0", height: "10rem" }}
                />
                {peerCallData?.status === "CANCELLED" ? (
                  <Title level={4} style={{ color: "#1e0f54" }}>
                    Your interview has been{" "}
                    <span style={{ color: "red" }}>canceled!</span>
                  </Title>
                ) : (
                  <></>
                )}
                <Title level={4} style={{ color: "#1e0f54" }}>
                  Please pick a time slot for your peer interview.
                </Title>
                <Paragraph>
                  Our hiring team will test you on your knowledge and expertise
                  in a live exercise.
                </Paragraph>
                <Calcom
                  calcomLink={calcomLink}
                  name={profile?.firstName + " " + profile?.lastName}
                  email={profile?.email}
                />
              </Space>
            </Col>
          </Row>
        ) : (
          // Show the scheduled interview details and options if the interview is already scheduled
          <Row style={{ margin: "auto 0" }} justify="center" align="middle">
            {profile && scheduled && (
              <Col>
                {profile.rejectedOn === "peer-interview-stage" ? (
                  <div></div>
                ) : (
                  <Row justify="center" align="middle">
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{
                        fontSize: "2rem",
                        margin: "1rem 0",
                      }}
                    />
                  </Row>
                )}

                {peerCallData?.rescheduleUid &&
                peerCallData?.status === "ACCEPTED" ? (
                  <div>
                    <Title level={4} style={{ color: "#1e0f54" }}>
                      Your interview has been{" "}
                      <span style={{ color: "green" }}>re-scheduled!</span>
                    </Title>
                    <Paragraph>
                      Scheduled date and time:
                      <Title level={5} style={{ color: "#1e0f54" }}>
                        {formattedDateTime}
                      </Title>
                    </Paragraph>
                  </div>
                ) : (
                  <div>
                    <Title level={4} style={{ color: "#1e0f54" }}>
                      Your interview has been{" "}
                      <span style={{ color: "green" }}>scheduled!</span>
                    </Title>
                    <Paragraph>
                      Scheduled date and time:
                      <Title level={5} style={{ color: "#1e0f54" }}>
                        {formattedDateTime}
                      </Title>
                    </Paragraph>
                  </div>
                )}

                <Paragraph>
                  {profile["peer-interview"] && //add "peer-interview" to the profile collection for the condition to work
                  profile["peer-interview"].startTime
                    ? `Your interview is scheduled for ${profile["peer-interview"].startTime}`
                    : ""}
                </Paragraph>
                {profile["peer-interview"] &&
                  profile["peer-interview"].startTime && (
                    <Row justify="center" align="middle">
                      <Space direction="horizontal">
                        <Button
                          onClick={() => {
                            window.open(
                              profile &&
                                profile["peer-interview"] &&
                                profile["peer-interview"].rescheduleLink,
                              "_blank"
                            );
                          }}
                          type="primary"
                        >
                          Reschedule
                        </Button>
                        <Button
                          onClick={() => {
                            window.open(
                              profile &&
                                profile["peer-interview"] &&
                                profile["peer-interview"].cancelLink,
                              "_blank"
                            );
                          }}
                        >
                          Cancel
                        </Button>
                      </Space>
                    </Row>
                  )}
              </Col>
            )}
          </Row>
        )
      ) : stages.peerInterviewCompleted ? (
        <Row style={{ margin: "auto 0" }} justify="center" align="middle">
          <Col>
            <Space direction="vertical">
              <img
                src={Winner}
                alt="winner"
                style={{ margin: "1rem 0", height: "10rem" }}
              />
              <Title level={4} style={{ color: "#1e0f54" }}>
                Congratulations!
              </Title>
              <Paragraph>
                You have passed your peer interview.
                <br />
                <strong>
                  You are just one step away from being a part of our exclusive
                  network of developers and engineers.
                </strong>
              </Paragraph>
            </Space>
          </Col>
        </Row>
      ) : (
        <Alert
          message="Warning"
          description="You are not allowed to setup peer interview schedule."
          type="warning"
          showIcon
        />
      )}
    </Layout>
  );
}
