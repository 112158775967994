import React, { useState, useContext, useEffect } from "react";
import { auth } from "../firebase";
import logo from "../images/logo.png";
import { useNavigate, Link, useLocation } from "@reach/router";
import {
  PoweroffOutlined,
  CodeOutlined,
  ApartmentOutlined,
  CheckCircleTwoTone,
  PhoneOutlined,
  BookOutlined,
  DesktopOutlined,
  IdcardOutlined,
  NodeIndexOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Badge,
  Button,
  Col,
  Row,
  Spin,
  Grid,
} from "antd";
import Profile from "../components/Profile Setup";
import AlgorithmsTest from "../components/Algorithms Test";
import InterviewSchedule from "../components/InterviewSchedule";
import PeerInterviewSchedule from "../components/PeerInterviewSchedule";
import Jobs from "../components/Jobs";
import Contracts from "../components/Contracts";
import ScreeningCall from "../components/ScreeningCall";
import ProfileFinalization from "../components/ProfileFinalization";
import PaymentSettings from "../components/PaymentSettings";
import ProfileProvider, { ProfileContext } from "../providers/ProfileProvider";
import { StageContext } from "../providers/CandidateStageProvider";
import qs from "qs";
import { UserContext } from "../providers/UserProvider";
import Roadmap from "../components/Roadmap";
const { Header, Content, Footer, Sider } = Layout;
const { useBreakpoint } = Grid;
const Dashboard = () => {
  const { user, loading } = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);
  const { stages, stagesLoading } = useContext(StageContext);
  const profileContext = useContext(ProfileContext);
  const profileLoading = profileContext.loading;
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();
  useEffect(() => {
    console.log("screens", screens);
  }, [screens]);
  const [selectedkey, setSelectedkey] = useState(1);
  useEffect(() => {
    if (!loading && !user) {
      navigate("/signin");
    }
  }, [user, loading, navigate]);
  useEffect(() => {
    setLoading(loading || stagesLoading || profileLoading);
  }, [loading, stagesLoading, profileLoading]);
  useEffect(() => {
    const queryParams = qs.parse(location.search);
    const activeTab = queryParams["?tab"] || queryParams["tab"];
    navigationController({ key: String(activeTab) });
  }, [location.search]);
  const toggleCollapsed = () => {
    let current = collapsed;
    current ? setCollapsed(false) : setCollapsed(true);
  };
  function logout() {
    auth.signOut();

    console.log("logout");
  }
  let [currentView, setCurrentView] = useState(<Roadmap />);
  const navigationController = ({ key }) => {
    switch (key) {
      case "1":
        setCurrentView(<Roadmap />);
        setSelectedkey(key);
        break;
      case "2":
        console.log("setting to requests");
        setCurrentView(
          <ProfileProvider>
            <Profile />
          </ProfileProvider>
        );
        setSelectedkey(key);
        break;
      case "3":
        console.log("setting to requests");
        setCurrentView(
          <ProfileProvider>
            <ScreeningCall />
          </ProfileProvider>
        );
        setSelectedkey(key);
        break;
      case "4":
        console.log("setting to requests");
        setCurrentView(
          <ProfileProvider>
            <AlgorithmsTest />
          </ProfileProvider>
        );
        setSelectedkey(key);
        break;
      case "5":
        console.log("setting to requests");
        setCurrentView(
          <ProfileProvider>
            <InterviewSchedule />
          </ProfileProvider>
        );
        setSelectedkey(key);
        break;
      case "6":
        setCurrentView(
          <ProfileProvider>
            <PeerInterviewSchedule />
            {/* <ProjectTest /> */}
          </ProfileProvider>
        );
        setSelectedkey(key);
        break;
      case "7":
        setCurrentView(
          <ProfileProvider>
            <ProfileFinalization />
          </ProfileProvider>
        );
        setSelectedkey(key);
        break;
      // case "7":
      //   console.log("setting to requests");
      //   setCurrentView(
      //     <ProfileProvider>
      //       <SkillsTest />
      //     </ProfileProvider>
      //   );
      //   setSelectedkey(key);
      //   break;
      case "8":
        console.log("setting to requests");
        setCurrentView(
          <ProfileProvider>
            <Jobs />
          </ProfileProvider>
        );
        setSelectedkey(key);
        break;
      case "9":
        console.log("setting to requests");
        setCurrentView(
          <ProfileProvider>
            <Contracts />
          </ProfileProvider>
        );
        setSelectedkey(key);
        break;
      case "10":
        console.log("setting to requests");
        setCurrentView(
          <ProfileProvider>
            <PaymentSettings />
          </ProfileProvider>
        );
        setSelectedkey(key);
        break;
      default:
        console.log("calling defaults");
        setCurrentView(<Roadmap />);
        setSelectedkey("1");
        break;
    }
  };

  return (
    <Layout style={{ height: "100vh" }}>
      {isLoading ? (
        <Row style={{ margin: "auto" }}>
          <Spin spinning={true} />
        </Row>
      ) : (
        <>
          <Header
            style={{
              background: "white",
              textAlign: "left",
              height: "60px",
              borderBottom: "1px solid #F2f2f2",
            }}
          >
            <Row align="middle" justify="space-between">
              <Col span="17">
                <img src={logo} alt="logo" style={{ width: "150px" }} />
              </Col>
              <Col span="5" style={{ textAlign: "right" }}>
                <Button type="ghost" onClick={logout} href="/">
                  <PoweroffOutlined /> Logout
                </Button>
              </Col>
            </Row>
          </Header>
          <Layout style={{ backgroundColor: "white" }}>
            {screens.xs || (screens.sm && !screens.md) ? (
              <>
                <Roadmap />
                <Footer style={{ background: "#1e0f54", color: "white" }}>
                  For a complete experience use a desktop
                </Footer>
              </>
            ) : (
              <>
                <Spin spinning={isLoading || !stages}>
                  {/* <div style={{ width: 256}}> */}
                  {/* <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{ marginBottom: 16 }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
            )}
          </Button> */}
                  <Sider
                    collapsed={collapsed}
                    onCollapse={toggleCollapsed}
                    theme="light"
                    width="250px"
                  >
                    <Menu
                      defaultSelectedKeys={["1"]}
                      mode="inline"
                      theme="light"
                      style={{ textAlign: "left" }}
                      onSelect={({ key }) => navigate(`/dashboard/?tab=${key}`)}
                      selectedKeys={[selectedkey]}
                    >
                      <Menu.Item
                        key="1"
                        // style={{
                        //   backgroundColor:
                        //     selectedKey === "1" ? "#FFF4FF" : "transparent",
                        // }}
                      >
                        <Link to="/dashboard/">
                          <NodeIndexOutlined style={{ fontSize: "1.2rem" }} />
                          <span>Roadmap</span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="2">
                        {stages && stages.profileCompleted ? (
                          <Badge
                            count={
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            }
                          >
                            <IdcardOutlined style={{ fontSize: "1.2rem" }} />
                          </Badge>
                        ) : (
                          <IdcardOutlined style={{ fontSize: "1.2rem" }} />
                        )}
                        <span>Profile</span>
                      </Menu.Item>
                      <Menu.Item
                        key="3"
                        disabled={
                          stages &&
                          (stages.profileCompleted === false ||
                            stages.screeningCallCompleted === true)
                        }
                      >
                        {stages && stages.screeningCallCompleted ? (
                          <Badge
                            count={
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            }
                          >
                            <PhoneOutlined style={{ fontSize: "1.2rem" }} />
                          </Badge>
                        ) : (
                          <PhoneOutlined style={{ fontSize: "1.2rem" }} />
                        )}
                        <span>Screening Call</span>
                      </Menu.Item>
                      <Menu.Item
                        key="4"
                        disabled={
                          stages &&
                          (stages.screeningCallCompleted === false ||
                            stages.algoTestCompleted === true)
                        }
                      >
                        {stages && stages.algoTestCompleted ? (
                          <Badge
                            count={
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            }
                          >
                            <CodeOutlined style={{ fontSize: "1.2rem" }} />
                          </Badge>
                        ) : (
                          <CodeOutlined style={{ fontSize: "1.2rem" }} />
                        )}
                        <span>Algorithms Test</span>
                      </Menu.Item>

                      <Menu.Item
                        key="5"
                        disabled={
                          stages &&
                          (stages.algoTestCompleted === false ||
                            stages.technicalInterviewCompleted === true)
                        }
                      >
                        {stages && stages.technicalInterviewCompleted ? (
                          <Badge
                            count={
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            }
                          >
                            <DesktopOutlined style={{ fontSize: "1.2rem" }} />
                          </Badge>
                        ) : (
                          <DesktopOutlined style={{ fontSize: "1.2rem" }} />
                        )}

                        <span>Technical Interview</span>
                      </Menu.Item>
                      <Menu.Item
                        key="6"
                        disabled={
                          stages &&
                          (stages.technicalInterviewCompleted === false ||
                            stages.peerInterviewCompleted === true)
                        }
                      >
                        {stages && stages.peerInterviewCompleted ? (
                          <Badge
                            count={
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            }
                          >
                            <ApartmentOutlined style={{ fontSize: "1.2rem" }} />
                          </Badge>
                        ) : (
                          <ApartmentOutlined style={{ fontSize: "1.2rem" }} />
                        )}
                        <span>Peer Interview</span>
                      </Menu.Item>
                      <Menu.Item
                        key="7"
                        disabled={
                          stages &&
                          (stages.technicalInterviewCompleted === false ||
                            stages.profileFinalizationCompleted === true)
                        }
                      >
                        {stages && stages.profileFinalizationCompleted ? (
                          <Badge
                            count={
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            }
                          >
                            <BookOutlined style={{ fontSize: "1.2rem" }} />
                          </Badge>
                        ) : (
                          <BookOutlined style={{ fontSize: "1.2rem" }} />
                        )}
                        <span>Onboarding</span>
                      </Menu.Item>
                      <Menu.Divider />
                      {/* <Menu.Item
                        key="7"
                        disabled={
                          stages &&
                          stages.profileFinalizationCompleted === false
                        }
                      >
                        {stages && stages.skillsTestsCompleted ? (
                          <Badge
                            count={
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            }
                          >
                            <ApartmentOutlined style={{ fontSize: "1.2rem" }} />
                          </Badge>
                        ) : (
                          <ApartmentOutlined style={{ fontSize: "1.2rem" }} />
                        )}

                        <span>Skills Test</span>
                      </Menu.Item> */}
                      {/* <Menu.Item
                        key="8" 
                       disabled={stages.profileFinalizationCompleted ? false : true}
                      > */}
                      {/* <Link to="/dashboard/jobs"> */}
                      {/* <ContainerOutlined style={{ fontSize: "1.2rem" }} />
                        <span>Jobs</span> */}
                      {/* </Link> */}
                      {/* </Menu.Item> */}
                      {/* <Menu.Item key="9" disabled>
                        <ReadOutlined style={{ fontSize: "1.2rem" }} />
                        <span>Contracts</span>
                      </Menu.Item>
                      <Menu.Item key="10" disabled>
                        <BankOutlined style={{ fontSize: "1.2rem" }} />
                        <span>Payment Settings</span>
                      </Menu.Item> */}
                    </Menu>
                  </Sider>
                </Spin>
                <Layout>
                  <Content
                    style={{
                      backgroundColor: "white",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {currentView}
                  </Content>
                  <Footer
                    style={{
                      backgroundColor: "white",
                      textAlign: "center",
                    }}
                  >
                    <Row align="middle" justify="center">
                      Teamo Inc. ©2023
                    </Row>
                  </Footer>
                </Layout>
              </>
            )}
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default Dashboard;
