import React, { useState, useContext, useEffect } from "react";
import { Layout, Row, Spin, Typography, Col, Space } from "antd";
import { retrieveAllDocuments } from "../../utilities/db";
import { UserContext } from "../../providers/UserProvider";
import CodingButton from "../../utilities/CodingPlatform";

const { Paragraph } = Typography;

const AssessmentOptions = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [hoveredCardId, setHoveredCardId] = useState(null);

  const fetchAllAvailableTests = async () => {
    setIsLoading(true);
    const dataReceived = await retrieveAllDocuments(
      "coderbyteAssesmentOptions"
    );
    setAssessments(dataReceived);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllAvailableTests();
  }, []);

  return (
    <Layout style={{ height: "100%", background: "white" }}>
      <Row style={{ margin: "auto 0" }} justify="center" align="middle">
        <Col>
          {isLoading ? (
            <Spin />
          ) : (
            <Space
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              {assessments.map((assessment) => (
                <Col
                  span={24}
                  key={assessment.test_id}
                  style={{
                    backgroundColor: "white",
                    height: "230px",
                    width: "230px",
                    borderRadius: "7px",
                    margin: "15px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "30px",
                    flexWrap: "wrap",
                    transition: "all .3s",
                    transform:
                      hoveredCardId === assessment.test_id
                        ? "translateY(-3px)"
                        : "translateY(0)",
                    boxShadow:
                      hoveredCardId === assessment.test_id
                        ? "2px 2px 10px 2px #dddddd"
                        : "2px 2px 10px 2px #eeeeee",
                  }}
                  onMouseEnter={() => setHoveredCardId(assessment.test_id)}
                  onMouseLeave={() => setHoveredCardId(null)}
                >
                  <Space
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Paragraph
                      style={{
                        fontSize: "20px",
                        fontWeight: "semibold",
                        color: "#1e0f54",
                        margin: "0",
                      }}
                    >
                      {assessment.display_name}
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontSize: "14px",
                        fontWeight: "semibold",
                        color: "#1e0f54",
                      }}
                    >
                      Challenges in the test:{" "}
                      {assessment.overview_stats.challenge_count}
                    </Paragraph>
                  </Space>
                  <CodingButton
                    user={user}
                    hoveredCardId={hoveredCardId}
                    assessmentId={assessment.test_id}
                    assessmentUrl={assessment.public_url}
                  />
                </Col>
              ))}
            </Space>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default AssessmentOptions;
