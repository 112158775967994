import React from "react";
import Connect from "../images/connect.svg";
import Platform from "../images/platform.svg";
import Success from "../images/relax.svg";
import { Layout, Row, Typography, List, Col, Divider } from "antd";

const { Title, Text } = Typography;

const Home = () => {
  const data = [
    "You have at least 2+ years of industry experience as a software engineer after you finished your college studies. If you have worked as a senior software engineer, tech lead, or architect, you will have even more job opportunities on Teamo.",
    "You can spend 5-8 hours on our programming tests, skill challenges, and video interviews. These tests were designed to find the right fit for the jobs we have on offer.",
    "You can adjust your work hours to overlap at least 4 hours a day with a company that you're working with.",
    "You are a fluent English communicator, and you will be able to communicate effectively over daily video calls with engineering managers at Software companies.",
    "You can function effectively and be valuable to engineering management without too much hand-holding and micromanagement. You don't need your manager to write detailed JIRA tickets for you. You are capable of discussing your manager's objectives and proposing a working roadmap and specific tasks to the manager for approval.",
    "You are an extremely proactive communicator, who understands the challenges of remote work and the need to communicate effectively to offset those challenges.",
    "You are good at feature planning and estimation. You can discuss business priorities with companies and propose sensible software/business tradeoffs that are in line with their priorities.",
  ];
  return (
    <>
      <Layout
        style={{
          minHeight: "100%",
          padding: "1rem 10rem",
          background: "white",
        }}
      >
        <Row>
          <Title level={4} style={{ color: "#1e0f54", marginBottom: "2rem" }}>
            Why should you join Teamo as a Developer?
          </Title>
        </Row>
        <Row justify="start" align="middle">
          <Col span={6}>
            <img src={Connect} alt="connect" style={{ height: "12rem" }} />
          </Col>
          <Col span={18}>
            <Text style={{ color: "#1e0f54", fontSize: "18px" }}>
              We{" "}
              <strong style={{ textDecoration: "underline" }}>connect</strong>{" "}
              developers from emerging countries to opportunities around the{" "}
              <strong style={{ textDecoration: "underline" }}>world</strong>.
              <br />
              Top tech companies use <strong>our platform</strong> to hire
              remote software developers.
            </Text>
          </Col>
        </Row>
        {/* <Row justify="center" align="middle" style={{}}>
          <Text style={{ color: "#1e0f54", fontSize: "18px" }}>
            Apply to Full-stack, Frontend, Backend, Mobile, DevOps, UI/UX,
            Machine Learning, Data Engineering, and other software development
            jobs.
          </Text>
        </Row> */}
        {/* <Row justify="end" align="middle">
          <Col span={18}>
            <Text style={{ color: "#1e0f54", fontSize: "18px" }}>
              Top tech companies use <strong>our platform</strong> to hire
              remote software developers.
            </Text>
          </Col>
          <Col span={6}>
            <img src={Platform} alt="connect" style={{ height: "10rem" }} />
          </Col>
        </Row>
        <Divider /> */}
        <Row justify="center" align="middle">
          <Col span={18}>
            <Text style={{ color: "#1e0f54", fontSize: "18px" }}>
              So once you are in our network, you won't have to look for another
              job.
            </Text>
          </Col>
          <Col span={6}>
            <img src={Success} alt="connect" style={{ height: "10rem" }} />
          </Col>
        </Row>
        <Row style={{ marginTop: "4rem" }}>
          <Title level={4} style={{ color: "#1e0f54" }}>
            You should apply if...
          </Title>
        </Row>
        <Row>
          <List
            size="small"
            bordered
            style={{ background: "white" }}
            dataSource={data}
            renderItem={(item) => (
              <List.Item style={{ textAlign: "left", minHeight: "4rem" }}>
                {item}
              </List.Item>
            )}
          />
        </Row>
      </Layout>
    </>
  );
};

export default Home;
