import React, { useEffect, useState, useContext } from "react";
import { Col, Collapse, Divider, List } from "antd";
import DOMPurify from "dompurify";
import { useNavigate } from "@reach/router";
import { UserContext } from "../providers/UserProvider";
import {
  Layout,
  Row,
  Tag,
  Icon,
  Button,
  Typography,
  Modal,
  Space,
  Avatar,
} from "antd";
import { firestore } from "../firebase";
import axios from "axios";
import config from "../firebaseConfig";
const { Panel } = Collapse;
const { Title, Text } = Typography;

export default function Jobs() {
  const [jobsData, setJobsData] = useState([]);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    getAll();
  }, []);
  const getAll = async () => {
    await firestore.collection("developerJobs").onSnapshot((snapshot) => {
      const documents = [];
      snapshot.forEach((doc) => {
        documents.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setJobsData(documents);
    });
  };
  // const sanitizeHtml = DOMPurify.sanitize(myHtml);
  function customExpandIcon(props) {
    if (props.expanded) {
      return (
        <a
          style={{ color: "black" }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <Icon type="minus" />
        </a>
      );
    } else {
      return (
        <a
          style={{ color: "black" }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <Icon type="plus" />
        </a>
      );
    }
  }
  function info(data) {
    Modal.info({
      title: "Job Requirements",
      content: (
        <>
          {data.map((data2) => (
            <>
              <Divider orientation="left">{data2.technologyName}</Divider>
              <List
                size="small"
                bordered
                dataSource={data2.skillsTags}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
              {/* <Row>
              <Text style={{ marginTop: "4px" }}>:</Text>

            </Row>

            <Row>

              {data2.skillsTags.map((data3) => (
                <Tag style={{ marginTop: "4px" }} color="blue">
                  {data3}
                </Tag>
              ))}
              <br />
            </Row> */}
            </>
          ))}
        </>
      ),
      onOk() {},
    });
  }

  async function applyJob(jobData) {
    // console.log(user.uid ,"uid", jobId, "jobId")
    let applicationData = {
      id: user.uid + ":" + jobData.id,
      email: user.email,
      jobTitle: jobData.title,
      status: "applied",
    };
    if (user) {
      axios
        .post(`${config.apiUrl}/adminRequests-jobApplications/`, {
          applicationData,
        })
        .then((res) => {
          console.log(res, "check data");
        });
    }
    console.log(applicationData);
  }
  return (
    <Layout
      style={{
        overflowY: "hidden",
        backgroundColor: "white",
      }}
    >
      <Collapse
        collapsible="header"
        style={{
          backgroundColor: "white",
          marginTop: "2.25rem",
          marginLeft: "2rem",
          marginRight: "2rem",
          border: "none",
        }}
        expandIcon={(props) => customExpandIcon(props)}
        accordion
      >
        {jobsData.map((data) => (
          <Panel
            style={{
              textAlign: "left",
              backgroundColor: "#eeeeee",
            }}
            header={
              <>
                <Row align="middle">
                  <Col span={2}>
                    <Avatar
                      shape="square"
                      style={{
                        width: "100px",
                        height: "100px",
                        marginRight: "7px",
                        marginLeft: "-30px",
                      }}
                      src={data.picurl}
                    />
                  </Col>
                  <Col span={19}>
                    <Layout
                      style={{
                        backgroundColor: "#eeeeee",
                      }}
                    >
                      <Title style={{ marginTop: "0.4rem" }} level={4}>
                        {" "}
                        {data.title}
                      </Title>
                      {/* <Text> {data.description}s</Text> */}

                      <Row style={{ marginTop: "8px" }}>
                        {data &&
                          data.tags &&
                          data.tags.map((data2) => (
                            <Tag color="red">{data2}</Tag>
                          ))}
                        {data &&
                          data.timeTags &&
                          data.timeTags.map((data2) => (
                            <Tag color="green">{data2}</Tag>
                          ))}
                      </Row>
                      <br />
                      <Row>
                        {data &&
                          data.technologyTagsGroup &&
                          data.technologyTagsGroup.map((data2) => (
                            <>
                              {data2.techTags.map((techtags) => (
                                <Tag color="blue">{techtags}</Tag>
                              ))}
                            </>
                          ))}
                      </Row>

                      {/* {data.jobApplied ?
                                        <Steps style={{
                                            marginTop: "16px"
                                        }} size="small" >
                                            <Step status={data.jobApplyStatus} disabled={true} title="Apply" />
                                            <Step status={data.jobProcessStatus} disabled={true} title="Review" />
                                            <Step status={data.jobOfferStatus} disabled={true} title="Offer" />
                                        </Steps> : ""
                                    } */}
                    </Layout>
                  </Col>
                  {/* {data.jobApplied ?
                                    <Button disabled={data.jobApplied == true || data.notEligible == true} style={{ marginTop: "30px", width: "125px" }} type="primary">Apply <CheckOutlined /></Button>
                                    : <Row>{data.notEligible ? <Tooltip placement="top" title="Not Eligible">
                                    <Button disabled={data.jobApplied == true || data.notEligible == true} style={{ marginTop: "30px", width: "125px" }} type="primary">Apply</Button> </Tooltip>
                                    : <Button disabled={data.jobApplied == true || data.notEligible == true} style={{ marginTop: "30px", width: "125px" }} type="primary">Apply</Button>
                                }</Row>
                            } */}

                  <Col span={3}>
                    <Space direction="vertical">
                      <Button
                        type="primary"
                        onClick={(event) => {
                          applyJob(data);
                          event.stopPropagation();
                        }}
                        block
                      >
                        {" "}
                        Apply{" "}
                      </Button>

                      <Button
                        type="ghost"
                        onClick={(event) => {
                          info(data.skillsTags);
                          event.stopPropagation();
                        }}
                        block
                      >
                        Requirements
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </>
            }
            key={data.id}
          >
            <div
              style={{
                padding: "0px 24px",
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.newHtml),
              }}
            />
            {/* {data.jobApplied ?
                            <Row style={{
                                display: "flex",
                                justifyContent: "center"
                            }}><Button disabled={data.jobApplied == true || data.notEligible == true} style={{ width: "125px" }} type="primary">Apply <CheckOutlined /></Button></Row>
                            : <Row style={{
                                display: "flex",
                                justifyContent: "center"
                            }}> {data.notEligible ? <Tooltip placement="top" color="red" title="Not Eligible">
                                <Button disabled={data.jobApplied == true || data.notEligible == true} style={{ width: "125px" }} type="primary">Apply</Button> </Tooltip> :
                                <Button disabled={data.jobApplied == true || data.notEligible == true} style={{ width: "125px" }} type="primary">Apply</Button>
                                }</Row>
                        } */}
          </Panel>
        ))}
      </Collapse>
    </Layout>
  );
}
