import React from "react";
import Platform from "../../images/platform.svg";
import { Row, Col, Space, Typography, Button } from "antd";
const { Paragraph, Title } = Typography;

export const TestLinkNotAccessible = ({ assessmentDetails }) => {
  return (
    <Row style={{ margin: "auto 0" }} justify="center" align="middle">
      <Col>
        <Space direction="vertical">
          <img
            src={Platform}
            alt="testNotAccessible"
            style={{ height: "10rem", margin: "1rem 0" }}
          />
          <Title level={4} style={{ color: "#1e0f54" }}>
            This was a 1 time link.
          </Title>
          <Paragraph>You have already received a test invite.</Paragraph>
          <Paragraph style={{ marginTop: "-20px" }}>
            Kindly check your email for test invite or
          </Paragraph>
          <Button
            type="ghost"
            onClick={() => {
              window.open(assessmentDetails?.testUrl, "_blank");
            }}
          >
            Go to Test
          </Button>
        </Space>
      </Col>
    </Row>
  );
};
