import React, { useState, useContext, useEffect } from "react";
import { Layout, Row, Spin, Button, Typography, Col, Alert, Space } from "antd";
import { ProfileContext } from "../providers/ProfileProvider";
import { StageContext } from "../providers/CandidateStageProvider";
import ScreeningImage from "../images/screeningcall.svg";
import { CheckCircleTwoTone } from "@ant-design/icons";
import Winner from "../images/winner.svg";
import { firestore } from "../firebase";
import { UserContext } from "../providers/UserProvider";
import Calcom from "./Calcom";

const { Title, Paragraph } = Typography;

export default function ScreeningCall() {
  const { profile, loading } = useContext(ProfileContext);
  const { stages, stagesLoading } = useContext(StageContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isAllowed, setAllowed] = useState(true);
  const [scheduled, setScheduled] = useState(false);
  const [screeningData, setScreeningData] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!loading || !stagesLoading) {
      if (
        profile.currentStage === "screeningCall" &&
        !stages.screeningCallCompleted
      ) {
        setAllowed(true);
      } else {
        setAllowed(false);
      }
    }
  }, [profile, stages, loading, stagesLoading]);

  useEffect(() => {
    if (!user) {
      return;
    }

    try {
      const fetchData = firestore
        .collection("screeningInterviews")
        .doc(user.uid)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setScreeningData(snapshot.data());
          } else {
            console.log("No data");
          }
        });

      return () => {
        fetchData();
      };
    } catch (error) {
      console.error("Error querying database:", error);
    }
  }, [user, firestore]);

  useEffect(() => {
    if (
      profile?.currentStage === "screeningCall" &&
      screeningData?.status === "ACCEPTED"
    ) {
      setScheduled(true);
    } else if (
      !screeningData?.createdAt ||
      screeningData?.status === "CANCELLED"
    ) {
      setScheduled(false);
    } else {
      setScheduled(true);
    }
    window.dataLayer.push({
      event: "screeningCallScheduled",
      scheduled: scheduled,
    });
  }, [screeningData, profile, setScheduled, scheduled]);

  useEffect(() => {
    setIsLoading(loading || stagesLoading);
  }, [loading, stagesLoading]);

  const dateTimeString = screeningData?.startTime;
  const dateTime = new Date(dateTimeString);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDateTime = dateTime.toLocaleString(undefined, options);

  return (
    <Layout style={{ height: "100%", background: "white" }}>
      {isLoading ? (
        <Row style={{ margin: "auto" }}>
          <Spin spinning={true} />
        </Row>
      ) : isAllowed ? (
        profile?.rejectedOn === "screening-call-stage" ? (
          <div>
            <Row justify="center" align="middle" style={{ margin: "10rem 0" }}>
              <Title level={4} style={{ color: "#1e0f54" }}>
                We apologize, but your application for this role has been
                rejected.
              </Title>
            </Row>
          </div>
        ) : !scheduled ? (
          <Row style={{ margin: "auto 0" }} justify="center" align="middle">
            <Col>
              <Space direction="vertical">
                <img
                  src={ScreeningImage}
                  alt="screening"
                  style={{ margin: "1rem 0", height: "10rem" }}
                />
                {screeningData?.status === "CANCELLED" &&
                screeningData?.startTime ? (
                  <Title level={4} style={{ color: "#1e0f54" }}>
                    Your interview has been{" "}
                    <span style={{ color: "red" }}>canceled!</span>
                  </Title>
                ) : (
                  <></>
                )}

                <Title level={4} style={{ color: "#1e0f54" }}>
                  Please pick a time slot for your screening interview.
                </Title>
                <Paragraph>
                  This will be a short call to get to know you better.
                </Paragraph>
                <Calcom
                  calcomLink="team/hiring-team/screening-call"
                  name={profile?.firstName + " " + profile?.lastName}
                  email={profile?.email}
                />
              </Space>
            </Col>
          </Row>
        ) : (
          <Row style={{ margin: "auto 0" }} justify="center" align="middle">
            {profile && scheduled && (
              <Col>
                {profile.rejectedOn === "screening-call-stage" ? (
                  <div></div>
                ) : (
                  <Row justify="center" align="middle">
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{
                        fontSize: "2rem",
                        margin: "1rem 0",
                      }}
                    />
                  </Row>
                )}

                {screeningData?.rescheduleUid &&
                screeningData?.status === "ACCEPTED" ? (
                  <div>
                    <Title level={4} style={{ color: "#1e0f54" }}>
                      Your interview has been{" "}
                      <span style={{ color: "green" }}>re-scheduled!</span>
                    </Title>
                    <Paragraph>
                      Scheduled date and time:
                      <Title level={5} style={{ color: "#1e0f54" }}>
                        {formattedDateTime}
                      </Title>
                    </Paragraph>
                  </div>
                ) : (
                  <div>
                    <Title level={4} style={{ color: "#1e0f54" }}>
                      Your interview has been{" "}
                      <span style={{ color: "green" }}>scheduled!</span>
                    </Title>
                    <Paragraph>
                      Scheduled date and time:
                      <Title level={5} style={{ color: "#1e0f54" }}>
                        {formattedDateTime}
                      </Title>
                    </Paragraph>
                  </div>
                )}

                <Paragraph>
                  {profile["screening-call"] &&
                  profile["screening-call"].startTime
                    ? `Your interview is scheduled for ${profile["screening-call"].startTime}`
                    : ""}
                </Paragraph>
                {profile["screening-call"] &&
                  profile["screening-call"].startTime && (
                    <Row justify="center" align="middle">
                      <Space direction="horizontal">
                        <Button
                          onClick={() => {
                            window.open(
                              profile &&
                                profile["screening-call"] &&
                                profile["screening-call"].rescheduleLink,
                              "_blank"
                            );
                          }}
                          type="primary"
                        >
                          Reschedule
                        </Button>
                        <Button
                          onClick={() => {
                            window.open(
                              profile &&
                                profile["screening-call"] &&
                                profile["screening-call"].cancelLink,
                              "_blank"
                            );
                          }}
                        >
                          Cancel
                        </Button>
                      </Space>
                    </Row>
                  )}
              </Col>
            )}
          </Row>
        )
      ) : stages.screeningCallCompleted ? (
        <Row style={{ margin: "auto 0" }} justify="center" align="middle">
          <Col>
            <Space direction="vertical">
              <img
                src={Winner}
                alt="winner"
                style={{ margin: "1rem 0", height: "10rem" }}
              />
              <Title level={4} style={{ color: "#1e0f54" }}>
                You have passed your Screening Interview
              </Title>
              <Paragraph>You can now move on to the next stage.</Paragraph>
            </Space>
          </Col>
        </Row>
      ) : (
        <Alert
          message="Warning"
          description="You are not allowed to setup the screening call."
          type="warning"
          showIcon
        />
      )}
    </Layout>
  );
}
