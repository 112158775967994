import React, { useContext, useEffect } from "react";
import { Typography, Col, Layout, Row, Spin, Steps, Button, Space } from "antd";
import { StageContext } from "../providers/CandidateStageProvider";
import { ProfileContext } from "../providers/ProfileProvider";
import { stageMap } from "../utilities/stageMap";
import Winner from "../images/winner.svg";
import Profile from "../images/profile.svg";
import Call from "../images/call.svg";
import Decide from "../images/decide.svg";
import Onboarding from "../images/onboarding.svg";
import Inspection from "../images/inspection.svg";
import { useState } from "react";
import { navigate } from "@reach/router";
const { Step } = Steps;
const { Text, Title } = Typography;
export default function Roadmap() {
  const { stages, stagesLoading } = useContext(StageContext);
  const { profile, loading } = useContext(ProfileContext);
  const [currentImage, setCurrentImage] = useState(null);
  useEffect(() => {
    if (profile && profile.currentStage) {
      if (profile.currentStage === "profile") {
        setCurrentImage(Profile);
      } else if (profile.currentStage === "screeningCall") {
        setCurrentImage(Call);
      } else if (profile.currentStage === "algoTest") {
        setCurrentImage(Decide);
      } else if (profile.currentStage === "technicalInterview") {
        setCurrentImage(Inspection);
      } else if (profile.currentStage === "peerInterview") {
        setCurrentImage(Inspection);
      } else if (profile.currentStage === "profileFinalization") {
        setCurrentImage(Onboarding);
      } else {
        setCurrentImage(Winner);
      }
    }
  }, [profile, stages]);
  return (
    <Layout
      style={{ background: "white", height: "100vh", overflowY: "scroll" }}
    >
      {loading || stagesLoading ? (
        <Spin spinning={true} />
      ) : (
        <Row
          style={{ minHeight: "80%", padding: "1rem" }}
          align="middle"
          justify="center"
        >
          <Col>
            <Row justify="center" style={{ padding: "1rem" }}>
              <img src={currentImage} alt="" style={{ height: "10rem" }} />
            </Row>
            <Row justify="center" align="middle">
              {profile && profile.currentStage === "active" ? (
                <Col>
                  <Space direction="vertical">
                    <Title style={{ fontSize: "1.25rem", color: "#1e0f54" }}>Congratulations!</Title>
                    <Text style={{ fontSize: "1rem" }}>
                     <strong>You have successfully completed the recruitment process 🚀</strong>
                    </Text>
                    <Row justify="center">
                      Our team is working on building your profile now. We will soon share a link with you once it is ready.<br />Your patience is appreciated.
                    </Row>
                  </Space>
                </Col>
              ) : (
                <Steps
                  style={{ width: "auto", height: "auto" }}
                  current={stageMap[profile.currentStage].value - 2}
                  direction="vertical"
                >
                  {Object.keys(stageMap)
                    .slice(0, 5)
                    .map((stage) => {
                      return (
                        <Step
                          style={{
                            cursor:
                              stageMap[profile.currentStage].value >=
                              stageMap[stage].value
                                ? "pointer"
                                : "not-allowed",
                          }}
                          onClick={() => {
                            if (
                              stageMap[profile.currentStage].value >=
                              stageMap[stage].value
                            ) {
                              navigate(
                                `/dashboard/?tab=${stageMap[stage].value}`
                              );
                            }
                          }}
                          key={stageMap[stage].value}
                          title={stageMap[stage].name}
                          description={stageMap[stage].description}
                        />
                      );
                    })}
                </Steps>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </Layout>
  );
}
