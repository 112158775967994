import React, { createContext, useContext, useState, useEffect } from "react";
import { firestore } from "../firebase";
import { UserContext } from "./UserProvider";

export const StageContext = createContext();
const StageProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [stages, setStages] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (user) {
      const unsubscribe = firestore
        .collection("candidateStages")
        .doc(user.uid)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            let data = snapshot.data();
            console.log("data", data);
            setStages(data);
            setLoading(false);
          } else {
            setStages(null);
            setLoading(false);
          }
        });
      return () => {
        unsubscribe();
      };
    }
  }, [user]);
  return (
    <StageContext.Provider
      value={{
        stages: stages,
        stagesLoading: loading,
      }}
    >
      {children}
    </StageContext.Provider>
  );
};

export default StageProvider;
