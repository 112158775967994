import React, { useContext, useEffect } from "react";
import { storage } from "../../firebase";

import {
  Row,
  Col,
  Form,
  Button,
  Typography,
  Tooltip,
  Upload,
  notification,
} from "antd";
import { ProfileContext } from "../../providers/ProfileProvider";
import { UploadOutlined, MinusOutlined } from "@ant-design/icons";
const ResumeUpload = (props) => {
  const { Text } = Typography;
  const userProfile = useContext(ProfileContext);
  const [resumeUrl, setResume] = props.resume;
  const updateFile = async (url) => {
    setResume(url);
  };
  const fileChange = async (info) => {
    console.log("infor", info);
    if (info.file.status === "uploading") {
    }
    if (info.file.status === "done") {
      let url = await info.file.xhr.ref.getDownloadURL();
      props.form.setFieldsValue({ resume: url });
      setResume(url);
    }
  };
  const customUpload = async ({ onError, onSuccess, file }) => {
    // const storage = firebase.storage()
    const storageRef = await storage.ref();
    let ext = file.name.split(".").pop();
    const imgFile = storageRef.child(`resumes/${file.uid}.${ext}`);
    try {
      const task = await imgFile.put(file);
      onSuccess(null, task);
    } catch (e) {
      console.log("e", e);
      onError(e);
    }
  };

  const beforeUpload = async (file) => {
    let ext = file.name.split(".").pop();
    if (ext !== "pdf") {
      notification.warning({
        message: "Invalid File Type",
        description: "Only .pdf files are supported",
      });
      return Promise.reject(false);
    } else {
      return true;
    }
  };

  // To disable submit button at the beginning.
  useEffect(() => {
    if (userProfile && userProfile.resume) {
      console.log("setting resume", userProfile.resume);
      setResume(userProfile.resume);
    }
  }, [userProfile, setResume]);

  useEffect(() => {
    if (props.form) {
    }
  }, [props.form]);

  return (
    <Row justify="center" gutter={16} style={{ textAlign: "center" }}>
      {resumeUrl ? (
        <Col span={24}>
          <Tooltip title="View file">
            <a href={resumeUrl} target="_blank" rel="noopener noreferrer">
              <Text underline strong>
                View Uploaded File
              </Text>
            </a>
          </Tooltip>
          <Tooltip title="Remove file">
            <Button
              onClick={(e) => {
                updateFile("");
              }}
              style={{ marginLeft: "1.25rem" }}
              danger
              shape="circle"
              size="small"
              icon={<MinusOutlined />}
            />
          </Tooltip>
        </Col>
      ) : (
        <Col span={24}>
          <Form.Item
            name={`resume`}
            style={{ textAlign: "center" }}
            rules={[
              {
                required: true,
                message: "Please upload resume!",
              },
            ]}
          >
            <Upload
              name={`resume`}
              accept=".doc,.docx,.pdf"
              style={{ textAlign: "left" }}
              beforeUpload={beforeUpload}
              customRequest={customUpload}
              onChange={fileChange}
            >
              <Button type="dashed" style={{ height: "auto", padding: "1rem" }}>
                <UploadOutlined /> Click to upload your resume/CV*
              </Button>
              <br />
              <Text
                style={{
                  fontSize: "0.8rem",
                  color: "grey",
                }}
              >
                <span style={{ color: "red" }}>*</span> Only{" "}
                <strong>.pdf</strong> supported
              </Text>
            </Upload>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default ResumeUpload;
