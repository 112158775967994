import React from "react";
import Winner from "../../images/winner.svg";
import { Row, Col, Space, Typography } from "antd";
const { Paragraph, Title } = Typography;

export const TestPassed = ({ stageName }) => {
  return (
    <Row style={{ margin: "auto 0" }} justify="center" align="middle">
      <Col span={24}>
        <Space direction="vertical">
          <img
            src={Winner}
            alt="winner"
            style={{ margin: "1rem 0", height: "10rem" }}
          />
          <Title level={4} style={{ color: "#1e0f54" }}>
            Congratulations
          </Title>
          <Paragraph>
            You have successfully completed the {stageName} and can move on to
            the next step!
          </Paragraph>
        </Space>
      </Col>
    </Row>
  );
};
