import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import firebaseConfig from "./firebaseConfig";
import axios from "axios";

axios.interceptors.request.use(
  async (config) => {
    if (firebase.auth().currentUser) {
      console.log(config.url)
      if (config.url.includes('coderbyte')) {
        return config;
      } else {
        let token = await firebase.auth().currentUser.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      }

    } else {
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error.response);
  }
);

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const FieldValue = firebase.firestore.FieldValue;
export const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    return firestore.collection("users").doc(uid);
  } catch (error) {
    return null;
  }
};
